<template>
  <v-app>
    <toolbar v-if="usuario != null"/>
    <v-main style="background: #EFEFEF;padding-bottom: 100px;">
      <router-view/>
    </v-main>
  </v-app>
</template>

<style>
* {
  font-family: "Noto Sans JP", sans-serif;
}
</style>

<script>
import { mapState } from 'vuex';
import toolbar from './components/toolbar.vue'
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components:{
    toolbar
  },
  computed:{
    ...mapState(['usuario'])
  }
};
</script>
