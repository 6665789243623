<template>
    
    <div v-if="usuario != null">

        <v-app-bar app flat style="background:white;">
            <img src="@/assets/logo.png" width="150"/>
            <v-spacer></v-spacer>
            {{ usuario.username }}
            <v-btn @click="signOut" class="btn white" text icon>
          <i class="fas fa-sign-out-alt red--text"></i>
        </v-btn>
        </v-app-bar>
        <v-divider></v-divider>

    </div>

</template>

<style>
.border-effect {
  animation: borderAnimation 0.5s infinite alternate;
}

@keyframes borderAnimation {
  0% {
    border: 0px solid transparent;
  }
  100% {
    border: 5px solid #00E4E8; /* Cambia #f00 al color que desees */
  }
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
export default {
    data(){
        return {
            showBorder: false,
        }
    },
    computed:{
        ...mapState(['usuario'])
    },
    methods:{
      ...mapActions(['signOut']),
    },
    mounted() {
    setInterval(() => {
      this.showBorder = !this.showBorder;
    }, 3000);
  },
}
</script>