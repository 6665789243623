import { initializeApp } from "firebase/app";
import { updateProfile, getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDukcwssxDnNnUap6W69Z8wWBY60WPBbOo",
  authDomain: "grupoficenca.firebaseapp.com",
  projectId: "grupoficenca",
  storageBucket: "grupoficenca.appspot.com",
  messagingSenderId: "1047472576511",
  appId: "1:1047472576511:web:d458237a8d3a1d092a39ca"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export { app, updateProfile, getAuth, signInWithEmailAndPassword, onAuthStateChanged }