import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { getAuth } from "../firebase";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    usuario:null
  },
  getters: {
  },
  mutations: {
    setUsuario(state, e) { 
      state.usuario = e; 
    },
  },
  actions: {
    signOut({ commit }) {
      if (confirm("¿Está seguro de que desea cerrar sesión?")) {
      commit('setUsuario', null);
      getAuth().signOut()
          .then(() => {
              console.log('Usuario deslogueado');
              window.open("/", "_self");
          })
          .catch((error) => {
              console.error('Error al desloguear:', error);
          });
      }
  },
  
    async detectarUsuario({commit}){
      try {
        const auth = getAuth();
        const usuario = auth.currentUser;
        await axios.post('https://apps.grupoficenca.com/php/login.php', {
          uid: usuario.uid
        }).then(res => {
          commit('setUsuario', res.data[0]);
        });
      } catch (error) {
        console.log(error);
      }
    },
    
    async changeView(viewType) {
      try {
        await axios.post('https://apps.grupoficenca.com/php/login.php?action=updateView', {
          uid: this.usuario.uid,
          view: viewType
        });
        this.view = viewType;
      } catch (error) {
        console.log(error);
      }
    }
    
  },
  modules: {
  }
})
