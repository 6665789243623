import Vue from 'vue';
import VueRouter from 'vue-router';
import { getAuth } from "../firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/Validating',
    name: 'validating',
    component: () => import('../components/validating.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../components/Dashboard.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/perfil',
    name: 'perfil',
    component: () => import('../components/Perfil.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/archivos',
    name: 'archivos',
    component: () => import('../components/Archivos.vue'),
    meta: { requireAuth: true }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requireAuth);
  const user = getAuth().currentUser;

  if (requiresAuth && !user) {
    next({ name: 'home' });  // Redirigir a la página de login si el usuario no está autenticado
  } else {
    next();
  }
});

export default router;
